import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Post/styled"

const AboutPage = () => {
  const { quemSouEuImg } = useStaticQuery(graphql`
    query {
      quemSouEuImg: file(
        relativePath: {
          eq: "profile/voce-empreendedor-digital-quem-sou-eu.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout visibleSideBar={true}>
      <SEO
        title="Quem sou eu"
        description="Muito prazer, meu nome é Willian D Santos e o meu objetivo é ajudar o máximo de pessoas a mudarem de vida através do marketing digital."
      />
      <S.PostHeader>
        <S.PostTitle>Sobre</S.PostTitle>
        <S.PostDescription>
          Muito prazer, meu nome é <strong>Willian D. Santos</strong> e o meu
          objetivo é ajudar o máximo de pessoas a mudarem de vida através do
          marketing digital.
        </S.PostDescription>
      </S.PostHeader>
      <S.MainContent>
        <S.PostImage
          fluid={quemSouEuImg.childImageSharp.fluid}
          alt="Foto de Willian D Santos - Empreendedor Digital"
        />
        <h2>Quem sou eu?</h2>
        <p>
          Eu vou te fornecer <strong>toda a ajuda</strong> que eu precisei
          aprender quando eu estava começando nesse mercado, para que você não
          perca tanto tempo até ter seus resultados e que não cometa os mesmos
          erros que eu cometi.
        </p>
        <p>
          Hoje eu tenho negócios na internet que mudaram completamente a minha
          vida e consequentemente a da minha família. E tudo que posso fazer é
          agradecer imensamente a esse mercado sensacional que é a internet.
        </p>
        <p>
          Antes de mais nada preciso dizer que sou uma pessoa normal, como você
          e tantos outros nesse Brasil maravilhoso que vivemos. Não tenho nada
          de especial ou diferente, apenas aprendi um pouco e principalmente{" "}
          <strong>não desisti.</strong>
        </p>
        <p>
          Com isso em mente, saiba que se eu pude mudar de vida, nada além de{" "}
          <strong>você mesmo</strong> te impede de fazer o mesmo.
        </p>
        <p>
          Muito antes de eu conhecer o marketing digital e trabalhar com a
          internet eu tinha uma vida bem diferente e para ser bem sincero,
          sempre incomodado.{" "}
          <strong>
            Não me conformava com o fato de viver para pagar contas.
          </strong>
        </p>
        <p>Vou te contar desde o ínicio.</p>
        <p>
          Cresci numa família humilde e bem padrão do Brasil e assim como
          muitos, sempre me foi ensinado que eu teria que ter uma ótima
          faculdade e entrar para uma grande companhia e assim ter o tão
          desejado sucesso.
        </p>
        <p>
          Porém desde o ensino médio eu já me moldava para ser algo diferente,
          essa ideia de ser <strong>apenas +1</strong> no mundo não me agradava
          e desde então eu passei a buscar fazer a diferença.
        </p>
        <p>
          Foi nos computadores e na internet que eu pude almejar ainda mais essa
          possibilidade, mas não antes de passar sim pelo mercado tradicional
        </p>
        <p>
          Trabalhei em <strong>grandes companhias</strong> como Nestlé,
          Santander, Itau, Accenture, whirlpool e etc. E para muitas pessoas eu
          estaria sendo muito realizado dentro delas, mas bem no fundo eu sentia
          que não.
        </p>
        <p>
          Eu cresci no interior e lá as remunerações não eram das melhores então
          eu me via limitado e uma rotina chata e por mais que seja um pouco
          menos feroz que a de São Paulo e grandes capitais,{" "}
          <strong>ainda assim era como uma prisão,</strong> tinha época que eu
          mal via o sol, era o dia todo dentro da empresa, fazendo sempre a
          mesma coisa e com o mesmo salário.
        </p>
        <p>
          Alguns momentos era super nítido que eu só conseguiria{" "}
          <strong>evoluir,</strong> quando alguma pessoa saísse da companhia
          seja por qual motivo fosse e com isso eu me voltei a uma outra paixão
          desde a infancia, que é computadores.
        </p>
        <p>
          Então comecei a focar em desenvolvimento e programação e decidi que
          iria fazer faculdade de Games. E com isso a mudança para São Paulo
          aconteceu, mas o sentimento era o mesmo, porém com a{" "}
          <strong>rotina mais louca ainda de sampa,</strong> de sair 5h da manhã
          e voltar muitas vezes meia noite apenas para casa.
        </p>
        <p>
          Nem só de tristeza é essa história, querendo ou não toda essa
          trejetória me ajudou a chegar onde cheguei e principalmente a
          facilitar algumas coisas com o{" "}
          <strong>
            conhecimento que eu tenho em programação para me auxiliar no
            marketing digital também.
          </strong>
        </p>
        <p>
          Depois de todo esse processo de aprendizado comecei a investir mais na
          internet e acabei descobrido muitas possibilidades, mesmo com toda a
          família sendo um pouco contra, por talvez receio ou não entender como
          tudo isso funciona, o que é natural, nem todos vão entender, alguns
          vão criticar e te julgar, mas se você der ouvidos a isso{" "}
          <strong>
            nunca iremos chegar a lugar algum, porque sempre tera algo para nos
            criticarem.
          </strong>
        </p>
        <p>
          E não se preocupem, quando falo investir foi apenas meu tempo para
          poder buscar formas de trabalhar de uma maneira melhor, com horários
          mais flexíves, sem patrão para ficar cobrando por coisas sem noção
          alguma (em alguns casos) e depois de um tempo pesquisando encontrei
          isso no{" "}
          <S.PostLink to="/o-que-e-marketing-digital/">
            Marketing Digital,{" "}
          </S.PostLink>
          mais específicamente com{" "}
          <S.PostLink to="/o-que-e-um-afiliado/">
            Marketing de Afiliados.
          </S.PostLink>
        </p>
        <p>
          E foi ai onde tudo começou,{" "}
          <strong>
            aquela visão maravilhosa de poder trabalhar onde quiser,{" "}
          </strong>
          no horário que eu quiser parecia até um sonho até que acordei e
          percebi que não basta só sonhar, que por mais que{" "}
          <strong>trabalhar com a internet seja simples</strong> não é fácil e
          nada vem sem esforço. No início achei que por ter muito conhecimento
          em programação seria fácil e simples, mas a realidade se provou um
          pouco contrario disso.
        </p>
        <p>
          Sem esquecer que o{" "}
          <strong>principal objetivo era ter mais tempo livre</strong> e no
          inicio as oportunidades que encontrava não eram escaláveis a longo
          prazo, vendo que eu teria que talvez trabalhar até mais do que antes
          eu comecei a buscar novas alternativas.
        </p>
        <p>
          A grande virada veio com o aprimoramento e foi quando conheci um curso
          completo,{" "}
          <strong>
            com toda certeza o mais completo do mercado sobre marketing digital.
          </strong>{" "}
          <a
            href="http://bit.ly/ved-curso-completo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Com esse curso eu aprendi
          </a>{" "}
          a aprimorar alguns conhecimentos que eu tinha e aprendi coisas que eu
          não fazia nem ideia, e isso me possibilitou criar uma{" "}
          <strong>estrutura sólida</strong> e que pudesse
          <strong>trabalhar para mim,</strong> para que assim eu conseguisse
          então ter mais liberdade sem prejudicar o quanto de dinheiro entra no
          bolso todo mês.
        </p>
        <p>
          Antes de chegar a essa conclusão, eu pesquisei muito no mercado e
          inclusive adquiri e fiz outros cursos, porém a maioria deles era
          focada em um único aspecto, ou era tráfego pago, ou era uma única
          plataforma social e não encontrava nenhum que{" "}
          <strong>fosse completo</strong> como esse e então vi muitos
          depoimentos positivos e principalmente vários alunos com ganhos
          maravilhosos e decidi que esse seria o <strong>meu caminho.</strong>
        </p>
        <p>
          <a
            href="http://bit.ly/ved-curso-completo"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mais detalhes sobre o curso aqui.
          </a>
        </p>
        <blockquote>
          Hoje consigo ter escalabilidade no meu negócio, trabalhar de onde for
          preciso, no meu horário, dedicando tempo a meu filho, minha família e
          tudo que eu desejar, tenho tempo para dedicar a meus hobbies e isso
          sem interferir no meu faturamento.
        </blockquote>
        <p>
          <strong>Criei esse blog para ajudar você</strong> a ter os mesmos
          resultados que tenho, então caso tenha alguma dúvida não tenha medo de
          me mandar um <S.PostLink to="/contato">contato</S.PostLink>
        </p>
        <p>
          Espero te ajudar em sua jornada! Lembre se, toda grande jornada começa
          no primeiro passo.
        </p>
        <p>Forte abraço,</p>
        <p>
          <strong>Willian D Santos</strong>
        </p>
      </S.MainContent>
    </Layout>
  )
}

export default AboutPage
